<template>
	<div>
		<!-- 新增提示 -->		
		<div class="remind" v-if="!message.IsOpen">
							<span style="margin-left:16px">订阅通知，当供货平台贷款余额不足时，我们将通过短信给你发送提醒。</span>
						    <span class="co" @click="handclick">前往订阅</span>						
		</div>
		<div class="filter-top">
			<!-- <span>账户余额（元）：{{accoutData.Balance}}</span> -->
			<div><span>账户余额（元）：</span>
				<button-permissions :datas="'yspayRecordbtn'">
					<span style="color:#409EFF;cursor: pointer;" @click="pushTorecord">充值记录</span>
				</button-permissions>

			</div>
			<div style="margin-top: 15px;"><span>{{accoutData.Balance}}</span>
				<button-permissions :datas="'yspayingbtn'">
					<el-button type='primary' style="margin-left: 15px;" @click='Paycharge()'>充值</el-button>
				</button-permissions>

			</div>
		</div>
		<div class="filter-container" style="background: #fff;padding: 15px;">
			<div class="filter-item">
				<label class="label">变更类型: </label>
				<el-select v-model="changeType" style="width: 150px;margin-right: 10px;">
					<el-option v-for="item in changeTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">变更时间: </label>
				<el-date-picker v-model="TimeValue" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
				 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00','23:59:59']">
				</el-date-picker>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<el-button type="" style="margin-left:30px;width:90px;" @click="handleExport">导出</el-button>
			</div>
		</div>
		<div class="table-container" style="background: #fff;padding:0 15px 15px 15px;">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="AddTime" label="时间"></el-table-column>
				<el-table-column prop="TypeName" label="变更类型" width='120px'></el-table-column>
				<el-table-column prop="ChangeBalance" label="变更金额">
					<template slot-scope="scope">
						<div v-if='scope.row.FreightMoney>0'>{{scope.row.ChangeBalance +  '(含运费:' + scope.row.FreightMoney + '元)'}}</div>
						<div v-else>{{scope.row.ChangeBalance}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AfterBalance" label="余额"></el-table-column>
				<el-table-column prop="OrderNo" label="订单"></el-table-column>
				<el-table-column label="备注" width='370px'>
					<template slot-scope="scope">
						<el-tooltip class="item-tooltip" popper-class="myaccout-note-detail-tooltip" effect="light" :content="scope.row.Remark"
						 placement="top-start">
							<div class="note-detail">
								{{scope.row.Remark}}
							</div>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="充值" :visible.sync="PayDialogShow" width="500px" v-loading='accountLoading'>
			<div v-if='IsadminOpen'>
				<div style="font-size:15px;margin-bottom:15px;">请汇款到以下账户，汇款成功后上传凭证，审核通过后充值到账</div>
				<div>收款方户名：{{accoutInfo.BankCardHolder}}</div>
				<div style="margin:10px 0">收款方开户行：{{accoutInfo.BankName}}</div>
				<div>收款方账户：{{accoutInfo.BankCardNo}}</div>
				<div style="text-align: right;margin-top: 15px;">
					<el-button style="margin-left: 30px;" type="primary" @click="SureUpload">我已汇款，上传凭证</el-button>
				</div>
			</div>
			<div v-else>平台未配置收款账户信息，请联系平台充值</div>
		</el-dialog>


		<!-- //添加充值信息 -->
		<el-dialog title="充值" :visible.sync="ChargeInfoVisi" width="600px" class="eidt_info" :closeOnClickModal='false'>
			<el-form :model="ChargeInfoForm" :rules="ChargeRules" ref="ChargeInfoForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="充值金额:" :rules="ChargeRules.chargeMoney" prop="chargeMoney">
					<el-input v-model="ChargeInfoForm.chargeMoney" @input='OnlyNumber(ChargeInfoForm.chargeMoney)' placeholder="请输入充值金额"></el-input>
				</el-form-item>
				<el-form-item label="汇款户名:" prop="RemitName" :rules="ChargeRules.RemitName">
					<el-input v-model="ChargeInfoForm.RemitName" maxlength="20" placeholder="请输入汇款户名"></el-input>
				</el-form-item>
				<el-form-item label="汇款账户:" prop="Remitaccount" :rules="ChargeRules.Remitaccount">
					<el-input v-model="ChargeInfoForm.Remitaccount" placeholder="请输入汇款账户"></el-input>
				</el-form-item>
				<el-form-item label="汇款日期:" prop="date" :rules="ChargeRules.date">
					<el-date-picker v-model="ChargeInfoForm.date" type="date" placeholder="请选择汇款日期" style="width: 300px;" value-format="yyyy-MM-dd"
					 format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="汇款凭证:" prop="PayimgUrl" :rules="ChargeRules.PayimgUrl">
					<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
					 list-type="picture-card" style="display: inline-block;marin-left:10px;">
						<img v-if="ChargeInfoForm.PayimgUrl" :src="imgUrl + ChargeInfoForm.PayimgUrl" class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div  style="text-align: center;margin-top: 30px;">
				<el-checkbox v-model="knowrules">
					<span style="color:#606266">阅读并同意 </span>
					<el-link type="primary" :underline="false" target="_blank"
					 href="https://jusnn6k8al.feishu.cn/docs/doccn7UeT5WcLtIEzVznqrlyFee"> {{rulesmsg.AgreementTitle}}</el-link>
				</el-checkbox>
			</div>
			<div style="text-align: center;margin-top: 20px;">
				<el-button style="width: 120px;" @click="ChargeInfoVisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="getSaveUserInfo('ChargeInfoForm')"
				 :loading="SureAddloading">确认</el-button>
				<!-- <el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveUserInfo('ChargeInfoForm')" :loading="SureAddloading">确认</el-button> -->
			</div>

		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		supplierMallBalanceInit,
		supplierMallBalanceList,
		collectionAccount,
		balanceRechargeRecordApply,
	    mallNoticeSubscribeConfig//获取消息订阅信息
	} from '@/api/TurnTomySelf'
	import {
		mallsystemAgreementInfoInit
	} from '@/api/sv1.0.0.js'
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			buttonPermissions
		},
		data() {
			var checkchargeMoney = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入充值金额'));
				} else if (Number(value) > 999999.99 || Number(value) < 0.01) {
					return callback(new Error('请输入0.01~999999.99内的数字，最多可输入2位小数'));
				} else {
					return callback();
				}
			};
			return {
				accoutInfo: {},
				accountLoading: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				ChargeInfoForm: {
					chargeMoney: '',
					RemitName: '',
					Remitaccount: '',
					date: '',
					PayimgUrl: '',
				},
				ChargeRules: {
					chargeMoney: [{
						required: true,
						trigger: 'blur',
						validator: checkchargeMoney
					}],
					RemitName: [{
						required: true,
						trigger: 'blur',
						message: '请输入汇款户名',
					}, {
						min: 1,
						max: 20,
						message: '最多输入20个字',
						trigger: 'blur'
					}],
					Remitaccount: [{
						required: true,
						trigger: 'blur',
						message: '请输入汇款账户',
					}, {
						min: 16,
						max: 19,
						message: '请输入16-21位数字',
						trigger: 'blur'
					}],
					date: [{
						required: true,
						trigger: 'change',
						message: '请选择汇款日期',
					}],
					PayimgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传汇款凭证',
					}]
				},
				SureAddloading: false,
				ChargeInfoVisi: false,
				IsadminOpen: true,
				PayDialogShow: false,
				changeType: null,
				changeTypeList: [{
						value: null,
						label: '全部'
					},
					{
						value: 1,
						label: '充值'
					},
					{
						value: 2,
						label: '扣减'
					},
					{
						value: 3,
						label: '货款退回'
					}
				],
				TimeValue: [],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				searchKey: '',
				loading: false,
				accoutData: {
					Balance: 0
				},

				exportUrl: config.EXPORT_URL,
				canSaveAccount: true,
				
				
				IsyspayRecordbtn:false,
				Isyspayingbtn:false,

				knowrules:false,
				rulesmsg:{},
				message:{}
			}
		},
		computed: {
			...mapGetters([
				'routerList'
			]),
			// checkedAll() {
			// 	console.log(this.multipleSelection, '当前页被选中的')
			// }
		},
		created() {
			this.getrules()
			this.getAccoutData()
			this.getList()
			this.getRouterList()
			this.getMessageList()//获取消息订阅
		},
		methods: {
			handclick(){
				this.$router.push({
                    path: '/setting/Newssubscription',
					// query:{
					// 	parameter:this.message
					// }
				})
			},
			//获取订阅消息
			async getMessageList(){
                try{
					let result_remind=await mallNoticeSubscribeConfig()
					console.log(result_remind.Result)
					this.message=result_remind.Result.find((i)=>{ return i.Type==5})	
				}
				finally{}
			},
			async getrules(){
				try{
					let res = await mallsystemAgreementInfoInit({
						AgreementType:6
					})
					if(res.IsSuccess){
						this.rulesmsg = res.Result
					}
				}finally{
					//
				}
			},
			getRouterList() {
				this.routerList.some(item => {
					if(item == 'yspayRecordbtn'){
						this.IsyspayRecordbtn = true
					}
					if(item == 'yspayingbtn'){
						this.Isyspayingbtn = true
					}
				})
				
				console.log(this.IsyspayRecordbtn,this.Isyspayingbtn,'数据')
				
				// if (this.routerList.some(item => {
				// 		return item == 'batchGivePoint' || item == 'batchDeductPoints' || item == 'batchEditStore'
				// 	})) {
				// 	this.isMoreShow = true
				// } else {
				// 	this.isMoreShow = false
				// }
			
			},
			//跳转到充值记录
			pushTorecord() {
				if(!this.IsyspayRecordbtn){
					this.$message({
						showClose: true,
						type: 'error',
						message: '没有操作权限，请联系管理员添加权限'
					});
					return
				}
				this.$router.push({
					path: '/assets/payRecord'
				});
			},
			handleAvatarSuccess(res, file) {
				// const isLt50k = file.size / 1024 < 50;
				// if (!isLt50k) {
				// 	alert('上传图片大小不能超过50kB哦!');
				// 	return false;
				// }
				this.ChargeInfoForm.PayimgUrl = res[0];
				this.$forceUpdate();
			},
			OnlyNumber(obj) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				this.ChargeInfoForm.chargeMoney = obj
			},
			getSaveUserInfo(formName) {
				if (this.canSaveAccount) {
					// console.log('有执行吗')
					if(this.knowrules){
						this.SaveUserInfo(formName)
					}else{
						this.$message.error(`请确认您已知晓并同意${this.rulesmsg.AgreementTitle}`)
						return 
					}
				}
			},
			//保存充值信息
			async SaveUserInfo(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.canSaveAccount = false
						this.SureAddloading = true
						try {
							let data = {
								RechargeMoney: this.ChargeInfoForm.chargeMoney,
								RechargeBankCardHolder: this.ChargeInfoForm.RemitName,
								RechargeBankCardNo: this.ChargeInfoForm.Remitaccount,
								RemittanceDate: this.ChargeInfoForm.date,
								RechargeImgUrl: this.ChargeInfoForm.PayimgUrl
							}
							let result = await balanceRechargeRecordApply(data)

							if (result.IsSuccess) {
								this.SureAddloading = false
								this.ChargeInfoVisi = false
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功'
								});
								this.getAccoutData()
								this.getList()
							}
						} catch (err) {
							this.SureAddloading = false
							this.canSaveAccount = true
						} finally {
							this.SureAddloading = false
							this.canSaveAccount = true
						}
					} else {
						return false;
					}
				});
			},
			//点击充值
			async Paycharge() {
				if(!this.Isyspayingbtn){
					this.$message({
						showClose: true,
						type: 'error',
						message: '没有操作权限，请联系管理员添加权限'
					});
					return
				}
				this.PayDialogShow = true
				try {
					this.accountLoading = true
					let result = await collectionAccount()
					if (result.IsSuccess) {
						this.accoutInfo = result.Result
						this.IsadminOpen = true
					}
				} catch (err) {
					this.accountLoading = false
					this.IsadminOpen = false
				} finally {
					this.accountLoading = false
				}

			},
			//点击已上传凭证
			SureUpload() {
				this.PayDialogShow = false
				this.ChargeInfoVisi = true
				this.$nextTick(function() {
					this.$refs.ChargeInfoForm.resetFields();
				})
				// this.$nextTick(function() {
				// 	this.$refs.ChargeInfoForm.clearValidate();
				// })

			},
			async getAccoutData() {
				let result = await supplierMallBalanceInit()
				this.accoutData = result.Result
				console.log(this.accoutData, '初始化余额')
			},
			//获取数据
			async getList() {
				this.loading = true
				try {
					this.TimeValue = (this.TimeValue == null) ? [] : this.TimeValue
					let data = {
						RecordType: this.changeType,
						StartTime: this.TimeValue[0],
						EndTime: this.TimeValue[1],
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
					}

					let result = await supplierMallBalanceList(data)
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (e) {
					console.log('e', e)
				} finally {
					this.loading = false
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList();
			},
			async handleExport() {
				this.loading = true
				try {
					let url = this.exportUrl + '/pc/supplierMallBalance/export?' +
						'&RecordType=' + (this.changeType ? this.changeType : '') +
						'&StartTime=' + (this.TimeValue[0] ? this.TimeValue[0] : '') +
						'&EndTime=' + (this.TimeValue[1] ? this.TimeValue[1] : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
.remind {
		width: 742px;
        height: 40px;
        background: #FBFDFF;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #409EEF;
		font-size: 14px;
		color: #606266  ;
		line-height: 40px;
		margin-bottom: 10px;
	}
.co{
	color:rgb(145, 204, 253);
	cursor: pointer;
}
	.upload-avatar {
		width: 145px;
		height: 145px;
		object-fit: contain;
	}

	.filter-top {
		background: #fff;
		padding: 20px 15px;
		width: 100%;
		margin-bottom: 10px;
	}

	.note-detail {
		width: 350px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/* autoprefixer: ignore next */
		-webkit-box-orient: vertical;
	}
</style>

<style lang="less">
	//不要加scoped！！！
	.myaccout-note-detail-tooltip {
		max-width: 350px !important;
	}
</style>
